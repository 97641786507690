import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "| ‘통신/로밍’ 메뉴, 카카오페이 플랫폼 내 신설",
  "| 타 통신금융 결합 플랫폼에서 볼 수 없었던 서비스 구성… 요금제, 디바이스, 로밍까지",
  "| 카카오톡 및 카카오페이앱 통해 진입·개통·상담 한 번에",
  "| 추후 시장이 원하는 새로운 통신·금융 서비스 선보일 것",
]

const content = () => (
  <div>
    <br />
    <div className="descImage">
      <div className="img">
        <img src="/images/press/35/35_img1.png" style={{ maxWidth: "300px", margin: "auto" }} alt="Figure2" />
      </div>
      <div className="desc">&lt;(위) 카카오페이 내 신설된 ‘통신/로밍’ 메뉴&gt;</div>
    </div>
    <p>
      <br />
      <br />
      스테이지파이브가 카카오페이 플랫폼에서 통신 서비스를 15일 오픈했다. 이는 스테이지파이브(대표 서상원)-카카오페이(대표 신원근)
      양사가 통신가입 시 카카오페이 인증서비스를 업계 최초 도입했던 ICT 규제샌드박스 사업*에서 나아간 본격적인 형태의 통신
      서비스이다
      <br />
      <br />
      금번 서비스 오픈을 통해 카카오톡 내 카카오페이 홈과 카카오페이앱 메인에 ‘통신/로밍’ 메뉴가 신설되었다. 카카오톡 사용자라면
      앱이탈 없이 ‘통신/로밍’ 메뉴를 통해 요금제, 데이터로밍, 리뉴드 디바이스 등을 둘러보고 구매까지 가능하다. 대부분의 요금제와
      데이터로밍은 USIM은 물론 eSIM으로도 제공되며, eSIM을 통할 경우 별도 배송 등의 기다림 없이 개통이 비대면으로 즉시 이루어진다.
      상담이 필요한 경우 카카오톡 채널을 통해 고객센터를 이용할 수 있는 것도 특장점이다.
      <br />
      <br />
      구체적으로, 요금제는 이통3사 요금제와 스펙은 같으면서 혜택은 더 좋은 ‘핀다로’ 상품과 절약형 요금제인 ‘Z시리즈’, 프리미엄
      요금제 ‘프라임’ 상품 등이 준비되었다. 요금제 개통 시에는 ‘카카오i’ AI엔진이 적용된 쾌적한 UX를 제공하여 고객 편의성 제고에
      초점을 맞췄다. 경쟁력 있고 다양한 요금제와 매칭 가능한 중고자급제 디바이스인 ‘리뉴드폰’도 카카오페이 내에서 구매가 가능하다.
      해당 메뉴에서는 늘어나는 해외여행객 수요에 맞춰 간편하고 저렴한 ‘데이터로밍’ 상품도 제공한다
      <br />
      <br />
      <div className="descImage">
        <div className="img">
          <img src="/images/press/35/35_img2.png" alt="Figure2" />
        </div>
        <div className="desc">&lt;(위) 오픈기념 로밍 무료쿠폰 제공 이벤트&gt;</div>
      </div>
      <br />
      <br />
      카카오페이 내 통신/로밍 메뉴 오픈을 기념하여, 오픈일인 15일부터 31일까지 해당 메뉴를 통해 요금제에 가입한 사용자들에게
      전세계 143개국에서 사용 가능한 데이터로밍 1일 무료 쿠폰을 증정하는 이벤트를 진행한다.
      <br />
      <br />
      스테이지파이브 관계자는 “최근 업계 기조와 발맞춰 복잡하고 어려웠던 통신을 더욱 명료하고 편리하게 하고, 사용자 맞춤형 혜택을
      제공하는데 주력하였다.”라며, “앞으로 양사가 선보일 새로운 통신금융 서비스도 기대해달라” 고 말했다.
      <br />
      <br />
      *카카오페이-KT-스테이지파이브 컨소시엄은 20년 9월 비대면 환경에서 이동통신 가입을 할 때 카카오페이 인증서와 PASS앱·은행계좌
      인증을 본인 확인 수단에 추가하여 편리한 본인확인이 이뤄질 수 있도록 하는 내용을 골자로 ICT 규제샌드박스 사업을 신청하여
      선정된 바 있다.
      <br />
    </p>
  </div>
)

const press35 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브"
      writer="커뮤니케이션실 pr@stagefive.com"
      date="2023년 5월 15일 조간 즉시배포 가능"
      pageInfo="(사진자료 메일 첨부)"
      title="스테이지파이브, 카카오페이에서 통신서비스 시작"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press35
